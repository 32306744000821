import React, { useState } from 'react';
import Style from './Home.module.scss';
import me from '../../img/self.png';
import classNames from 'classnames';
import EmojiBullet from "./EmojiBullet";
import SocialIcon from "./SocialIcon";
import { Box, TextField, Button } from "@mui/material";
import { info } from "../../info/Info";

export default function Home({ innerRef }) {
   const [query, setQuery] = useState('');
   const [response, setResponse] = useState('');

   const handleAskMe = () => {
      // Placeholder for AI query handling logic
      setResponse(`You asked: "${query}". (Response logic will be implemented here.)`);
   };

   return (
      <Box
         ref={innerRef}
         component={'main'}
         display={'flex'}
         flexDirection={{ xs: 'column', md: 'row' }}
         alignItems={'center'}
         justifyContent={'center'}
         minHeight={'calc(100vh - 175px)'}
         id={'home'}
      >
         <Box
            className={classNames(Style.avatar, Style.shadowed)}
            alt={'image of developer'}
            style={{ background: info.gradient }}
            component={'img'}
            src={me}
            width={{ xs: '35vh', md: '40vh' }}
            height={{ xs: '35vh', md: '40vh' }}
            borderRadius={'50%'}
            p={'0.75rem'}
            mb={{ xs: '1rem', sm: 0 }}
            mr={{ xs: 0, md: '2rem' }}
         />
         <Box>
            <h1>
               I'm{' '}
               <span
                  style={{
                     background: info.gradient,
                     WebkitBackgroundClip: 'text',
                     WebkitTextFillColor: 'transparent',
                  }}
               >
                  {info.firstName}
               </span>
               <span className={Style.hand}>🤚🏻</span>
            </h1>
            <h2>An {info.position}</h2>
            <Box component={'ul'} p={'0.8rem'}>
               {info.miniBio.map((bio, index) => (
                  <EmojiBullet key={index} emoji={bio.emoji} text={bio.text} />
               ))}
            </Box>
            <Box display={'flex'} gap={'1.5rem'} justifyContent={'center'} fontSize={{ xs: '2rem', md: '2.5rem' }}>
               {info.socials.map((social, index) => (
                  <SocialIcon key={index} link={social.link} icon={social.icon} label={social.label} />
               ))}
            </Box>

            {/* AskMe AI Section */}
            <Box mt={'2rem'} textAlign={'center'}>
               <h2>🤖</h2>
               <TextField
   value={query}
   onChange={(e) => setQuery(e.target.value)}
   placeholder="Ask me about myself!"
   variant="outlined"
   fullWidth
   margin="normal"
   InputProps={{
      sx: {
         height: '2.5rem', // Adjust height
         fontSize: '0.9rem', // Smaller text size
         borderRadius: '8px', // Rounded corners
         padding: '0 0.5rem', // Reduce inner padding
      },
   }}
   sx={{
      '& .MuiOutlinedInput-root': {
         '& fieldset': {
            borderColor: '#ccc', // Subtle border color
         },
         '&:hover fieldset': {
            borderColor: '#555', // Darker border on hover
         },
         '&.Mui-focused fieldset': {
            borderColor: '#007BFF', // Highlighted border on focus
         },
      },
   }}
/>

               <Button variant="contained" color="primary" onClick={handleAskMe}>
                  Ask Me
               </Button>
               {response && (
                  <Box mt={'1rem'} p={'1rem'} border={'1px solid #ccc'} borderRadius={'8px'} bgcolor={'#f9f9f9'}>
                     {response}
                  </Box>
               )}
            </Box>
         </Box>
      </Box>
   );
}
