// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Terminal_shadowed__lFFoO {\n  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);\n}\n\ni {\n  padding-left: 0.5rem;\n}\ni.Terminal_red__-LLSJ {\n  color: #FF6057;\n}\ni.Terminal_amber__NRd3E {\n  color: #FFBD2E;\n}\ni.Terminal_green__deSLX {\n  color: #27C93F;\n}\n\n.Terminal_terminal__YWKw0 p, .Terminal_terminal__YWKw0 li {\n  margin: 0.6rem 0;\n  color: #f8f8f8;\n}\n.Terminal_terminal__YWKw0 a, .Terminal_terminal__YWKw0 a:visited {\n  animation: Terminal_changeColors__9o4ok 3s infinite;\n  font-weight: bold;\n}\n\n@keyframes Terminal_changeColors__9o4ok {\n  0% {\n    color: #00a47f;\n  }\n  33.3% {\n    color: #1d91e3;\n  }\n  66.6% {\n    color: #d419fe;\n  }\n  100% {\n    color: #00a47f;\n  }\n}", "",{"version":3,"sources":["webpack://./src/variables.modules.scss","webpack://./src/components/about/Terminal.module.scss"],"names":[],"mappings":"AAWA;EACE,4CAAA;ACVF;;AAAA;EACE,oBAAA;AAGF;AADE;EACE,cDFE;ACKN;AAAE;EACE,cDLK;ACOT;AACE;EACE,cDRI;ACSR;;AAIE;EACE,gBAAA;EACA,cDnBI;ACkBR;AAIE;EACE,mDAAA;EACA,iBAAA;AAFJ;;AAMA;EACE;IACE,cAAA;EAHF;EAKA;IACE,cAAA;EAHF;EAKA;IACE,cAAA;EAHF;EAKA;IACE,cAAA;EAHF;AACF","sourcesContent":["$purple: #8D53FF;\n$pink: #CA6BE6;\n$white: #f8f8f8;\n$dark: #1f1f1f;\n$red: #FF6057;\n$yellow: #FFBD2E;\n$green: #27C93F;\n$lightgray: #c9c9c9;\n\n$transition: all 250ms ease;\n\n.shadowed {\n  box-shadow: 0 .5rem 1rem rgba(0,0,0,0.4);\n}","@import \"../../variables.modules\";\n\ni {\n  padding-left: 0.5rem;\n\n  &.red {\n    color: $red;\n  }\n\n  &.amber {\n    color: $yellow;\n  }\n\n  &.green {\n    color: $green;\n  }\n}\n\n.terminal {\n  p, li {\n    margin: 0.6rem 0;\n    color: $white;\n  }\n\n  a, a:visited {\n    animation: changeColors 3s infinite;\n    font-weight: bold;\n  }\n}\n\n@keyframes changeColors {\n  0% {\n    color: #00a47f;\n  }\n  33.3% {\n    color: #1d91e3;\n  }\n  66.6% {\n    color: #d419fe;\n  }\n  100% {\n    color: #00a47f;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shadowed": "Terminal_shadowed__lFFoO",
	"red": "Terminal_red__-LLSJ",
	"amber": "Terminal_amber__NRd3E",
	"green": "Terminal_green__deSLX",
	"terminal": "Terminal_terminal__YWKw0",
	"changeColors": "Terminal_changeColors__9o4ok"
};
export default ___CSS_LOADER_EXPORT___;
