import React, { useState } from 'react'; // Import useState from React

import Style from './About.module.scss';
import Terminal from "./Terminal";
import {Box} from "@mui/material";
import {info} from "../../info/Info";

export default function About({innerRef}) {
    const firstName = info.firstName.toLowerCase();
//About me
    function aboutMeText() {
        return <>
            <p><span style={{color: info.baseColor}}>{firstName}{info.lastName.toLowerCase()} $</span> cat
                about_{firstName} </p>
            <p><span style={{color: info.baseColor}}>about{firstName} <span
                className={Style.green}></span> $ </span>
                {info.bio}
            </p>
        </>;
    }


//Career Transition
function careerTransitionText() {
    return <>
        <p><span style={{color: info.baseColor}}>{firstName}{info.lastName.toLowerCase()} $</span> cat Career_Journey</p>
        <p><span style={{color: info.baseColor}}>Career_Journey{firstName} <span
            className={Style.green}></span> $ </span>
            {info.careertransition}
        </p>
    </>;
}

//My Explorations
function explorationsText() {
    return <>
        <p><span style={{color: info.baseColor}}>{firstName}{info.lastName.toLowerCase()} $</span> cd explorations/experience
        </p>
        <p><span style={{color: info.baseColor}}>explorations/experience <span
            className={Style.green}></span> $</span> ls</p> 
        <ul>
            {info.explorations.map((experience, index) => (
                <li key={index} style={{marginBottom: '1.7rem'}}>
                    <p style={{color: '#FEB040'}}><strong>{experience.title}</strong></p> 
                    <p><em>{experience.date} - {experience.location}</em></p>
                    <p>Company: {experience.company}</p>
                    <p>{experience.description}</p>
                
                </li>
                                   
            ))}
        </ul>
    </>;
}

// Educational Qualification
function educationText() {
    return <>
        <p><span style={{color: info.baseColor}}>{firstName}{info.lastName.toLowerCase()} $</span> cd education/Qualifications
        </p>
        <p><span style={{color: info.baseColor}}>education/Qualifications <span
            className={Style.green}></span> $</span> ls</p> 
        <ul>
            {info.education.map((education, index) => (
                <li key={index} style={{marginBottom: '1.7rem'}}>
                    <p style={{color: '#FEB040'}}><strong>{education.title}</strong></p> 
                    <p><em>{education.date} - {education.location}</em></p>
                    <p>University: {education.university}</p>
                    <p>{education.description}</p>
                
                </li>
                                   
            ))}
        </ul>
    </>;
}


    function skillsText() {
        return <>
            <p><span style={{color: info.baseColor}}>{firstName}{info.lastName.toLowerCase()} $</span> cd expertise/recent
            </p>
            <p><span style={{color: info.baseColor}}>expertise/recent <span
                className={Style.green}></span> $</span> ls</p>
            <p style={{color: '#FEB040'}}> Cybersecurity Concepts</p>
            <ul className={Style.skills}>
                {info.skills.Cybersecurity.map((cybersecurity, index) => <li key={index}>{cybersecurity}</li>)}
            </ul>


            <p style={{color: '#FEB040'}}> IT Infrastructure Management</p>
            <ul className={Style.skills}>
                {info.skills.ITInfrastructure.map((itinfrastructure, index) => <li key={index}>{itinfrastructure}</li>)}
            </ul>


            <p style={{color: '#FEB040'}}> Automation and Programming</p>
            <ul className={Style.skills}>
                {info.skills.Automationandprogramming.map((automationandprogramming, index) => <li key={index}>{automationandprogramming}</li>)}
            </ul>
        </>;
    }
// Edit this to add "main" in green className={Style.green}>(main)</span> $</span> ls</p> 
    function certificationsText() {
        return <>
            <p><span style={{color: info.baseColor}}>{firstName}{info.lastName.toLowerCase()} $</span> cd certifications
            </p>
            <p><span style={{color: info.baseColor}}>certifications <span
                className={Style.green}></span> $</span> ls</p> 
            <ul>
                {info.certifications.map((cert, index) => (
                    <li key={index} style={{marginBottom: '1.7rem'}}>
                        <p style={{color: '#FEB040'}}><strong>{cert.title}</strong> - <em>{cert.date}</em></p>
                        <p>Issued by: {cert.authority} <small><a href={cert.link }> Verify Online</a></small></p>
                        <p>{cert.description}</p>
                    
                    </li>
                                       
                ))}
            </ul>
        </>;
    }

    //hobbies
    function hobbiesText() {
        return <>
            <p><span style={{color: info.baseColor}}>{firstName}{info.lastName.toLowerCase()} $</span> cd
                hobbies/interests</p>
            <p><span style={{color: info.baseColor}}>hobbies/interests <span
                className={Style.green}></span> $</span> ls</p>
            <ul>
                {info.hobbies.map((hobby, index) => (
                    <li key={index}><Box component={'span'} mr={'1rem'}>{hobby.emoji}</Box>{hobby.label}</li>
                ))}
            </ul>
        </>;
    }

 
    function openSourceSoftwareText() {
        const [visibleItems, setVisibleItems] = useState(9); // Initial items to show
    
        const handleShowMore = () => {
            setVisibleItems(prev => prev + 9); // Show more items on click
        };
    
        return (
            <>
                <p><span style={{ color: info.baseColor }}>{firstName}{info.lastName.toLowerCase()} $</span> cd technologies/OpenSource</p>
                <p><span style={{ color: info.baseColor }}>technologies/OpenSource <span className={Style.green}></span> $</span> display all</p>
    
                <Box 
                    display="flex" 
                    justifyContent="center" 
                    flexWrap="wrap" 
                    mt={'2rem'} 
                    width={'100%'}
                    sx={{
                        background: 'linear-gradient(130deg, #807778, #ad796b, #d9d9d9)', // Gradient background
                        opacity: 0.7, // Opacity for the gradient
                        padding: '1rem', // Padding around the icons
                        borderRadius: '0.5rem' // Optional: Rounded corners for the container
                    }}
                >
                    {info.openSourceSoftware.slice(0, visibleItems).map((software, index) => (
                        <Box 
                            key={index} 
                            display="flex" 
                            flexDirection="column" 
                            alignItems="center" 
                            m={2} 
                            width={'28%'} // Fits 3 icons per row
                            textAlign={'center'}
                        >
                            <Box
                                component="div"
                                sx={{
                                    width: 64,
                                    height: 64,
                                    borderRadius: '50%', // Perfect round
                                    overflow: 'hidden', // Ensure overflow is hidden
                                    display: 'flex', // Center the image inside the container
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxShadow: '0 0 0 0.2px rgba(0, 0, 0, 0.3)'
                                }}
                            >
                                <img
                                    src={software.icon}
                                    alt={software.name}
                                    style={{
                                        width: 'calc(100% - 7px)',
                                        height: 'calc(100% - 7px)',
                                        padding: '7px',
                                        objectFit: 'cover', // Scale image to cover the container while preserving aspect ratio
                                    }}
                                />
                            </Box>
                            <Box mt={1} fontSize={'0.9rem'} style={{ color: 'black' }}>{software.name}</Box>
                        </Box>
                    ))}
                </Box>
                {visibleItems < info.openSourceSoftware.length && (
                    <Box textAlign="center" mt={4}>
                        <button 
                            onClick={handleShowMore} 
                            style={{ padding: '10px 20px', fontSize: '0.8rem', cursor: 'pointer', backgroundColor: '#FEB040', color: 'black', borderRadius: '5px' }}
                        >
                            Show More
                        </button>
                    </Box>
                )}
            </>
        );
    }
    
    

    return (
        <Box ref={innerRef} display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'} id={'about'}>
            <Terminal text={aboutMeText()} title="whoami"/>
            <Terminal text={careerTransitionText()} title="My Transition Journey to IT"/>

            <Terminal text={explorationsText()} title="Experiences"/>
            <Terminal text={educationText()} title="Education"/>
            <Terminal text={certificationsText()} title="Professional Certifications"/> {/* Added the certifications section */}
            <Terminal text={skillsText()} title="Practical and Conceptual Expertise"/>
            <Terminal text={openSourceSoftwareText()} title="Open Source Technologies Proficiency"/> {/* New Section */}

            <Terminal text={hobbiesText()} title="Hobbies and Interests"/>


        </Box>
    )
}
