

import self from "../img/self.png"
import mock1 from "../img/mock1.png"
import mock2 from "../img/mock2.png"
import mock3 from "../img/mock3.png"
import mock4 from "../img/mock4.png"
import mock5 from "../img/mock5.png"


/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

//export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
export let colors = ["rgb(128, 119, 120)", "rgb(173, 121, 107)", "rgb(217, 217, 217)"];

/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */
/* 
This variable will change the layout of the website from multipage to single, scrollable page
*/
export let singlePage = false;

/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const handleDownloadCV = () => {
    const link = document.createElement('a');
    link.href = process.env.PUBLIC_URL + '/MansoorCV.pdf'; // This points to the PDF file in the public directory
    link.download = 'MansoorCV.pdf'; // The name of the downloaded file
    link.click();
};


export const info = {
    firstName: "Mansoor",
    lastName: "Ahmed",
    initials: "Hi",
    position: <small>IT Enthusiast!</small>,
    selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: '☕',
            text: 'Fueled by coffee'
        },
        {
            emoji: '🌎',
            text: (
                <>
                    Based in Murree, Pakistan 🇵🇰 - 🌡<small> 37.06°F</small>
                </>
            )
        },
        {
            emoji: '🛩',
            text: (
                <>
                    Traveled to 🇦🇪 UAE, 🇴🇲 Oman
                </>
            )
        },
        {
            emoji: '📍',
            text: (
                <>
                    Favorite Destination 🇩🇪 Berlin, Germany
                </>
            )
        },

        {
            emoji: "💼",
            text: (<>Freelancer‎ ‎ ‎ 
            <a href="https://www.fiverr.com/mahmed_m" target="_blank" rel="noopener noreferrer">
            <img src="/fiverr.png" style={{width: '30px', height: '30px', 'vertical-align': 'middle'}} />
            </a>‎ ‎ 
            <a href="https://www.upwork.com/freelancers/linuxpsycho" target="_blank" rel="noopener noreferrer">
            <img src="/upwork.png" style={{width: '40px', height: '40px', 'vertical-align': 'middle'}} />
            </a>
            </>
            )
        },
        {
            emoji: "📧",
            text: <a href="mailto:mahmed4094@gmail.com" style={{ textDecoration: 'none', color: 'inherit' }}>mahmed4094@gmail.com</a>
        },
        {
            emoji: "📄",
            text: <span onClick={handleDownloadCV} style={{ cursor: 'pointer', textDecoration: 'underline' }}>Download CV</span>
        }
    ],
    socials: [
        
        {
            link: "https://github.com/manoonabbasi",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://linkedin.com",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        }
// Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
// Just change the links so that they lead to your social profiles.

    ],
    bio: "Passionate IT professional with a strong foundation in computer science and a keen interest in cybersecurity, AI, and Eager to learn and contribute to cutting-edge technological advancements.",
    skills:
        {
            Cybersecurity: ['Network Security and Hardening', 'Incident Response and Threat Assessment', 'Vulnerability Assessment', 'Cryptography and Data Encryption', 'Secure Software Development and Testing', 'Information Privacy Regulations and Compliance'],
            ITInfrastructure: ['Network Administration and Configuration', 'Cloud Services and Collaboration', 'VPN Configuration and Proxies', 'Firewall Setup and Management', 'Systems and Configuration Management', 'Active Directory and LDAP integration'],
            Automationandprogramming: ['Python Programming for Automation', 'DevOps Practices: AWS, Git', 'IT Automation with Python', 'SIEM, Kubernetes']
        },
        careertransition: "Transitioning from hospitality to IT, Cybersecurity, and Entrepreneurship shows my adaptability and passion for learning. Google IT certifications in Support, Automation, and Cybersecurity bolstered my technical skills. My background in front desk operations, time management, and business management supports my work in learning, entrepreneurship, and freelancing.",
    
    hobbies: [
        {
            label: 'Trekking',
            emoji: '🚶🏼'
        },
        {
            label: 'Gardening',
            emoji: '👩🏻‍🌾'
        },
        {
            label: 'Movies',
            emoji: '🎥'
        },
        {
            label: 'Cooking',
            emoji: '🌶'
        }
// Same as above, change the emojis to match / relate to your hobbies or interests.
// You can also remove the emojis if you'd like, I just think they look cute :P
    ],
    education: [
        {
            title: "BS(CS) COMPUTER SCIENCE",
            date: "2007 – 2011",
            location: "Islamabad, PK",
            university: "Federal Urdu University of Arts, Science & Technology",
            description: "I developed key IT skills in networking, operating systems, and programming. This foundation helps me tackle complex challenges and stay updated with technological advancements."
        }
    ],
    certifications: [
        {
            title: "Cyber Security Professional",
            date: "July 2023",
            authority: "Google",
            link: "https://coursera.org/verify/professional-cert/ZJ6S9K92QPRX",
            description: "ertified in cybersecurity, skilled in network security, incident response, and vulnerability assessment. Committed to staying updated with industry trends and making a positive impact on organizational cybersecurity efforts."
        },
        {
            title: "IT Automation with Python",
            date: "Aug 2023",
            authority: "Google",
            link: "https://coursera.org/verify/professional-cert/A3GXG8AJC6G7",
            description: "Completed Google IT Automation with Python Certification, fueled by fervent coding passion. Proficient in Python basics, effective problem-solving. Ready to apply skills, contribute to projects, and collaborate within teams. Excited for Python development journey, personal growth, impactful contributions."
        },
        {
            title: "IT Support Certification",
            date: "August 2023",
            authority: "Google",
            link: "https://coursera.org/verify/professional-cert/85ASMSR6ASVV",
            description: "Accomplished Google IT Support Certification with a genuine interest in technical issue resolution. Skilled at troubleshooting hardware/software, strong IT fundamental foundation. Ready to apply skills, contribute, and collaborate. Excited for a rewarding IT support journey, creating meaningful impact through excellence."
        }
    ],
    explorations: [
        {
            title: "FREELANCE PROFESSIONAL: AI, CYBERSECURITY, AND AUTOMATION",
            date: "CURRENT",
            location: "Murree, PK",
            company: "Fiverr, Upwork, Freelancer.com",
            description: "Multi-certified freelance IT professional with Google IT certifications in Automation with Python, Cybersecurity, and IT Support.Skilled in software configuration, security solutions, and IT automation with AI. Committed to continuous learning in Cybersecurity,AI, and Automation."
        },
        {
            title: "CO-FOUNDER",
            date: "02/2024 – CURRENT",
            location: "Post Falls, ID, United States",
            company: "DUBAI DESERT TOURS LLC",
            description: "Co-founded and manage a Dubai tours business. Developed and maintain the website with booking systems. Use Docker and GitHub CI/CD for efficient deployments. Optimize processes with coding and oversee all IT operations."
        },
        {
            title: "CO-FOUNDER",
            date: "12/2019 – 03/2023",
            location: "St. Petersburg, FL, United States",
            company: "MANOONS LLC",
            description: " Founded and managed a dropshipping business for fashion leather bags. Developed the e-commerce site, integrated payments, and optimized SEO. Automated order processing and CRM. Utilized web development, database, and cloud skills to drive growth.."
        },
        {
            title: "ROVE EXPERIENCE ASSISTANT",
            date: "03/2018 – 09/2021",
            location: "Dubai, United Arab Emirates",
            company: "EMAAR HOSPITALITY LLC",
            description: "This transferable skill equipped me with time management, being a trainer, and critical and analytical mentality. Being a team player, I was privileged as Manager on Duty to oversee the hotel's night operation team."
        },
        {
            title: "HOTEL FRONT OFFICE CLERK",
            date: "04/2014 – 02/2018",
            location: "Dubai, United Arab Emirates",
            company: "INTERCONTINENTAL HOTELS GROUP",
            description: "With this transferable skill I proudly embraced cultural diversity, being a team player, and emotional intelligence. Coreresponsibilities were hosting events and making reservations."
        },
        {
            title: "COMPUTER OPERATOR",
            date: "07/05/2012 – 08/12/2012",
            location: "Islamabad, PK",
            company: "LEGEND ENTERPRISES",
            description: "Computer maintenance, supplier relations, and clerical tasks."
        }
    ],
    openSourceSoftware: [
        { name: "Proxmox", icon: "/icons/proxmox.svg" },
        { name: "Kuma", icon: "/icons/uptimekuma.svg" },
        { name: "AdGuardHome", icon: "/icons/adguard.svg" },
        { name: "Homepage", icon: "/icons/homepage.svg" },
        { name: "Grafana", icon: "/icons/grafana.svg" },
        { name: "Prometheus", icon: "/icons/prometheus.svg" },
        { name: "OpenVPN", icon: "/icons/openvpn.svg" },
        { name: "Nextcloud", icon: "/icons/nextcloud.svg" },
        { name: "Joplin", icon: "/icons/joplin.svg" },
        { name: "OpenProject", icon: "/icons/openproject.svg" },
        { name: "Elasticsearch", icon: "/icons/elasticsearch.svg" },
        { name: "Caddy", icon: "/icons/caddy.svg" },
        { name: "Portainer", icon: "/icons/portainer.svg" },
        { name: "Authentik", icon: "/icons/authentik.svg" },
        { name: "Pterodactyl", icon: "/icons/pterodactyl.svg" },
        { name: "DockerSwarm", icon: "/icons/docker.svg" },
        { name: "Kubernetes", icon: "/icons/kubernetes.svg" },
        { name: "Terraform", icon: "/icons/terraform.svg" },
        { name: "InfluxDB", icon: "/icons/influxdb.svg" },
        { name: "FastAPI", icon: "/icons/fastapi.svg" },
        { name: "GraphQL", icon: "/icons/graphql.svg" },
        { name: "OdooERP", icon: "/icons/odoo.svg" },
        { name: "WireGuard", icon: "/icons/wireguard.svg" },
        { name: "OpenWRT", icon: "/icons/openwrt.svg" },
        { name: "PfSense", icon: "/icons/pfsense.svg" },
        { name: "Tomcat", icon: "/icons/apachetomcat.svg" },
        { name: "AirByte", icon: "/icons/airbyte.svg" },
        { name: "OpenShift", icon: "/icons/redhatopenshift.svg" },
        { name: "Plesk", icon: "/icons/plesk.svg" },
        { name: "OpenStack", icon: "/icons/openstack.svg" },
        { name: "ROS", icon: "/icons/ros.svg" },
        { name: "NodeRED", icon: "/icons/nodered.svg" },
        { name: "FreeCAD", icon: "/icons/freecad.svg" },
        { name: "ApacheSuperset", icon: "/icons/apachesuperset.svg" },
        { name: "Pimcore", icon: "/icons/pimcore.svg" },
    ]
    ,
    portfolio: [ // This is where your portfolio projects will be detailed
        {
            title: "Project 1",
            live: "https://paytonpierce.dev", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
            source: "https://github.com/paytonjewell", // this should be a link to the **repository** of the project, where the code is hosted.
            image: mock1
        },
        {
            title: "Project 2",
            live: "https://paytonpierce.dev",
            source: "https://github.com/paytonjewell",
            image: mock2
        },
        {
            title: "Project 3",
            live: "https://paytonpierce.dev",
            source: "https://github.com/paytonjewell",
            image: mock3
        },
        {
            title: "Project 4",
            live: "https://paytonpierce.dev",
            source: "https://github.com/paytonjewell",
            image: mock4
        },
        {
            title: "Project 5",
            live: "https://paytonpierce.dev",
            source: "https://github.com/paytonjewell",
            image: mock5
        }
    ]
}

