// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navbar_shadowed__JPZA2 {\n  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);\n}\n\nnav {\n  transition: all 400ms;\n  z-index: 1;\n}\n\n.Navbar_dark__bj3b1 {\n  background-color: #1f1f1f;\n}\n\n.Navbar_light__YEA4i {\n  background-color: #f8f8f8;\n}\n\n.Navbar_active__l7FQP {\n  border-bottom: 5px solid;\n  border-image-slice: 1;\n}\n\nli {\n  transition: all 250ms ease;\n}\nli:hover {\n  transform: translateY(-3px);\n  transition: all 250ms ease;\n}\n\n.Navbar_link__clc6E:hover {\n  color: unset;\n}", "",{"version":3,"sources":["webpack://./src/variables.modules.scss","webpack://./src/components/Navbar.module.scss"],"names":[],"mappings":"AAWA;EACE,4CAAA;ACVF;;AAAA;EACE,qBAAA;EACA,UAAA;AAGF;;AAAA;EACE,yBDLK;ACQP;;AACA;EACE,yBDXM;ACaR;;AACA;EACE,wBAAA;EACA,qBAAA;AAEF;;AACA;EACE,0BDbW;ACeb;AAAE;EACE,2BAAA;EACA,0BDjBS;ACmBb;;AAEA;EACE,YAAA;AACF","sourcesContent":["$purple: #8D53FF;\n$pink: #CA6BE6;\n$white: #f8f8f8;\n$dark: #1f1f1f;\n$red: #FF6057;\n$yellow: #FFBD2E;\n$green: #27C93F;\n$lightgray: #c9c9c9;\n\n$transition: all 250ms ease;\n\n.shadowed {\n  box-shadow: 0 .5rem 1rem rgba(0,0,0,0.4);\n}","@import \"../variables.modules\";\n\nnav {\n  transition: all 400ms;\n  z-index: 1;\n}\n\n.dark {\n  background-color: $dark;\n}\n\n\n.light {\n  background-color: $white;\n}\n\n.active {\n  border-bottom: 5px solid;\n  border-image-slice: 1;\n}\n\nli {\n  transition: $transition;\n\n  &:hover {\n    transform: translateY(-3px);\n    transition: $transition;\n  }\n}\n\n.link:hover {\n  color: unset;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shadowed": "Navbar_shadowed__JPZA2",
	"dark": "Navbar_dark__bj3b1",
	"light": "Navbar_light__YEA4i",
	"active": "Navbar_active__l7FQP",
	"link": "Navbar_link__clc6E"
};
export default ___CSS_LOADER_EXPORT___;
